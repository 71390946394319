// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.
//@import '~@angular/material/theming'; error since angular/material v15

@import '../node_modules/font-awesome/scss/font-awesome.scss';

@import "src/variables";
@import "src/responsive";

@import "src/animations";


$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
$dark-dividers: rgba(black, 0.12);
$dark-focused: rgba(black, 0.12);
$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);
$light-dividers: rgba(white, 0.12);
$light-focused: rgba(white, 0.12);

$md-deck: (
	50 : #e4e7eb,
	100 : #bbc4cd,
	200 : #8e9dac,
	300 : #61768a,
	400 : #3f5871,
	500 : #1d3b58,
	600 : #1a3550,
	700 : #152d47,
	800 : #11263d,
	900 : #0a192d,
	A100 : #69a0ff,
	A200 : #3680ff,
	A400 : #0360ff,
	A700 : #0056e8,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #ffffff,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #000000,
		A200 : #ffffff,
		A400 : #ffffff,
		A700 : #ffffff,
	)
);

$md-deck-accent: (
	50 : #e8f7ff,
	100 : #c5ecff,
	200 : #9ee0ff,
	300 : #77d3ff,
	400 : #5ac9ff,
	500 : #3dc0ff,
	600 : #37baff,
	700 : #2fb2ff,
	800 : #27aaff,
	900 : #1a9cff,
	A100 : #5ac9ff,
	A200 : #3dc0ff,
	A400 : #37baff,
	A700 : #1a9cff,
	contrast: (
		50: $dark-primary-text,
		100: $dark-primary-text,
		200: $dark-primary-text,
		300: $dark-primary-text,
		400: $dark-primary-text,
		500: $light-primary-text,
		600: $light-primary-text,
		700: $light-primary-text,
		800: $light-primary-text,
		900: $light-primary-text,
		A100: $dark-primary-text,
		A200: $light-primary-text,
		A400: $light-primary-text,
		A700: $light-primary-text,
	)
);

$md-pink: (
	50 : #f9e4e6,
	100 : #f1bdc0,
	200 : #e79197,
	300 : #dd646d,
	400 : #d6434d,
	500 : #cf222e,
	600 : #ca1e29,
	700 : #c31923,
	800 : #bd141d,
	900 : #b20c12,
	A100 : #ffdfdf,
	A200 : #ffacae,
	A400 : #ff797c,
	A700 : #ff5f63,
	contrast: (
		50 : #000000,
		100 : #000000,
		200 : #000000,
		300 : #000000,
		400 : #ffffff,
		500 : #ffffff,
		600 : #ffffff,
		700 : #ffffff,
		800 : #ffffff,
		900 : #ffffff,
		A100 : #ffffff,
		A200 : #000000,
		A400 : #ffffff,
		A700 : #000000,
	)
);

$deck-primary: mat.m2-define-palette($md-deck);
$deck-accent: mat.m2-define-palette($md-deck-accent, A200, A100, A400);
$deck-warn: mat.m2-define-palette($md-pink);
$proxima-typography: mat.m2-define-legacy-typography-config(
				$font-family: 'proxima-nova',
				$display-4:     mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
				$display-3:     mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
				$display-2:     mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
				$display-1:     mat.m2-define-typography-level($font-size-XXL, 40px, 400),
				$headline:      mat.m2-define-typography-level($font-size-XL, 32px, 400),
				$title:         mat.m2-define-typography-level($font-size-LL, 32px, 500),
				$subheading-2:  mat.m2-define-typography-level($font-size-L, 28px, 400),
				$subheading-1:  mat.m2-define-typography-level($font-size-MM, 24px, 400),
				$body-2:        mat.m2-define-typography-level($font-size-M, 24px, 500),
				$body-1:        mat.m2-define-typography-level($font-size-M, 20px, 400),
				$caption:       mat.m2-define-typography-level($font-size-S, 20px, 400),
				$button:        mat.m2-define-typography-level($font-size-M, 14px, 500),
				$input:         mat.m2-define-typography-level(inherit, 1.125, 400)
);
@include mat.all-component-typographies($proxima-typography);

@include mat.core();

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$deck-theme: mat.m2-define-light-theme((
	color: (
		primary: $deck-primary,
		accent: $deck-accent,
		warn: $deck-warn,
	),
	typography: $proxima-typography,
	shadow: primary,
));

@include mat.all-component-themes($deck-theme);

//@import "src/app/custom.component.scss-theme";

//@include sidenav-component-theme($deck-theme);

/* You can add global styles to this file, and also import other style files */

$font-size-rem-reference: 13px;
html {
	font-size: $font-size-rem-reference;
}
html, body {
	height: 100%;
	color: $k-primary;
}
body {
	font-size: $font-size-M;
}

.disable-two-finger-back {
	overscroll-behavior-x: none;
}

body {
	margin: 0;
	font-family: 'proxima-nova', sans-serif;
}

.poppins {
	font-style: Poppins !important;
}

.mat-stroked-button:not(.mat-primary):not(.mat-warn):not(.mat-accent) {
	color: $k-primary;
}

a {
	text-decoration: none;
	color: $k-accent;
	cursor: pointer;
}

h1 {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 500;
	font-size: 28px;
	letter-spacing: -0.0902108px !important;
	color: $k-primary;
}

/* Margin global variables */

.mt-xxs {
	margin-top: 2px;
}

.mt-xs {
	margin-top: 5px;
}

.mt-s {
	margin-top: 10px;
}

.mt-m {
	margin-top: 15px;
}

.mt-l {
	margin-top: 20px;
}

.mt-xl {
	margin-top: 25px;
}

.mt-xxl {
	margin-top: 50px;
}

.mt-auto {
	margin-top: auto;
}


.mr-xxs {
	margin-right: 2px;
}

.mr-xs {
	margin-right: 5px;
}

.mr-s {
	margin-right: 10px;
}

.mr-m {
	margin-right: 15px;
}

.mr-l {
	margin-right: 20px;
}

.mr-xl {
	margin-right: 25px;
}

.mb-n {
	margin-bottom: 0;
}

.mb-xxs {
	margin-bottom: 2px;
}

.mb-xs {
	margin-bottom: 5px;
}

.mb-s {
	margin-bottom: 10px;
}

.mb-m {
	margin-bottom: 15px;
}

.mb-l {
	margin-bottom: 20px;
}

.mb-xxl {
	margin-bottom: 50px;
}

.mb-xl {
	margin-bottom: 25px;
}

.ml-xs {
	margin-left: 5px;
}

.ml-s {
	margin-left: 10px;
}

.ml-m {
	margin-left: 15px;
}

.ml-l {
	margin-left: 20px;
}

.ml-xl {
	margin-left: 25px;
}

.ml-xxl {
	margin-left: 30px;
}

.ml-auto {
	margin-left: auto;
}

.mb-minus-m {
	margin-bottom: -15px;
}

.m-auto {
	margin: auto;
}
.m-auto-0 {
	margin: auto 0;
}
.m-0-auto {
	margin: 0 auto;
}

/* End margin global variable */

/* Padding global variables */

.pt-n {
	padding-top: 0;
}

.pt-xs {
	padding-top: 5px;
}

.pt-s {
	padding-top: 10px;
}

.pt-m {
	padding-top: 15px;
}

.pt-l {
	padding-top: 20px;
}

.pt-xl {
	padding-top: 25px;
}

.pr-n {
	padding-right: 0;
}

.pr-xs {
	padding-right: 5px;
}

.pr-s {
	padding-right: 10px;
}

.pr-m {
	padding-right: 15px;
}

.pr-l {
	padding-right: 20px;
}

.pr-xl {
	padding-right: 25px;
}

.pb-n {
	padding-bottom: 0;
}

.pb-xs {
	padding-bottom: 5px;
}

.pb-s {
	padding-bottom: 10px;
}

.pb-m {
	padding-bottom: 15px;
}

.pb-l {
	padding-bottom: 20px;
}

.pb-xl {
	padding-bottom: 25px;
}

.pl-n {
	padding-left: 0;
}

.pl-xs {
	padding-left: 5px;
}

.pl-s {
	padding-left: 10px;
}

.pl-m {
	padding-left: 15px;
}

.pl-l {
	padding-left: 20px;
}

.pl-xl {
	padding-left: 25px;
}

.pl-xxl {
	padding-left: 50px;
}

/* End padding global variable */

/* Tables global variable */

th, td {
	padding: 0 10px;
}

/* End tables global variable */

/* Input design global */


.text-center {
	text-align: center;
}
.text-justify {
	text-align: justify;
}

.text-end {
	text-align: end;
}

.text-start {
	text-align: start;
}

.text-underline {
	text-decoration: underline;
}

.c-p {
	cursor: pointer;
}

.c-g {
	cursor: grab;
}

.c-na {
	cursor: not-allowed;
}

.code {
	padding: 5px 20px;
	background-color: $k-lightgrey;
	color: #2b2b2b;
	font-family: Consolas, Monaco, Lucida Console;
	font-size: 0.8rem !important;
	overflow-x: auto;
	border-radius: 3px;
	border: 1px solid $k-lightgrey-3;
}

.capitalize {
	text-transform: capitalize;
}

.italic {
	font-style: italic;
}

.page-title-icon-bg {
	display: block;
	text-align: center;
	margin-left: -9px;
}

.disp-b {
	display: block;
}

.disp-g {
	display: grid;
}

.disp-g-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.disp-g-3 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.disp-g-3-auto {
	display: grid;
	grid-template-columns: repeat(3, auto);
}

.disp-g-4 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
}

.disp-g-5 {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
}

.disp-g-6 {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}

.disp-g-7 {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
}

.disp-g-10 {
	display: grid;
	grid-template-columns: repeat(10, 1fr);
}

.disp-g-12 {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
}

.g-c-s2 {
	grid-column: span 2;
}

.g-c-s3 {
	grid-column: span 3;
}

.disp-ib {
	display: inline-block;
}

.disp-f {
	display: flex;
}

.disp-none {
	display: none;
}

.disp-if {
	display: inline-flex;
}

.a-i-c {
	align-items: center;
}

.a-i-e {
	align-items: end;
}

.a-i-s {
	align-items: start;
}

.a-i-fs {
	align-items: flex-start;
}

.a-i-fe {
	align-items: flex-end;
}

.a-i-b {
	align-items: baseline;
}

.j-c-c {
	justify-content: center;
}

.j-c-fe {
	justify-content: flex-end;
}

.j-c-sb {
	justify-content: space-between;
}

.j-c-sa {
	justify-content: space-around;
}

.j-c-se {
	justify-content: space-evenly;
}

.j-c-s {
	justify-content: flex-start;
}

.j-c-e {
	justify-content: end;
}

.f-d-col {
	flex-direction: column;
}

.f-d-r {
	flex-direction: row;
}

.f-d-rrev {
	flex-direction: row-reverse;
}

.uppercase {
	text-transform: uppercase;
}

.lowercase {
	text-transform: lowercase;
}

.fw-700 {
	font-weight: 700;
}

.fw-600 {
	font-weight: 600;
}

.fw-500 {
	font-weight: 500;
}

.fw-400 {
	font-weight: 400;
}

.fw-300 {
	font-weight: 300;
}

.primary {
	color: $k-primary !important;
}

.bkg-primary {
	background-color: $k-primary !important;
}

.bkg-primary-light {
	background-color: $k-primary-light !important;
}

.border-primary {
	border: $border-XS solid $k-primary !important;
}

.border-primary-S {
	border: $border-S solid $k-primary !important;
}

.accent {
	color: $k-accent !important;
}

.bkg-accent {
	background-color: $k-accent !important;
}

.bkg-accent-light {
	background-color: $k-accent-light !important;
}

.bkg-accent-transparent {
	background-color: $k-accent-transparent !important;
}

.border-accent {
	border: $border-XS solid $k-accent !important;
}

.accent-secondary {
	color: $k-accent-secondary !important;
}

.bkg-accent-secondary {
	background-color: $k-accent-secondary !important;
}

.bkg-accent-secondary-light {
	background-color: $k-accent-secondary-light !important;
}

.bkg-accent-secondary-very-light {
	background-color: $k-accent-secondary-very-light !important;
}

.bkg-accent-secondary-transparent {
	background-color: $k-accent-secondary-transparent !important;
}

.border-accent-secondary {
	border: $border-XS solid $k-accent-secondary !important;
}

.warn {
	color: $k-warn !important;
}

.bkg-warn {
	background-color: $k-warn !important;
}

.bkg-warn-light {
	background-color: $k-warn-light !important;
}

.bkg-warn-transparent {
	background-color: $k-warn-transparent !important;
}

.border-warn {
	border: $border-XS solid $k-warn !important;
}

.danger {
	color: $k-error !important;
}

.bkg-danger {
	background-color: $k-error !important;
}

.bkg-danger-light {
	background-color: $k-error-light !important;
}

.bkg-danger-very-light {
	background-color: $k-error-very-light !important;
}

.bkg-danger-transparent {
	background-color: $k-error-transparent !important;
}

.border-danger {
	border: $border-XS solid $k-error !important;
}

.transparent {
	color: rgba(12, 38, 51, 0.10) !important;
}

.bkg-transparent {
	background-color: rgba(12, 38, 51, 0.10) !important;
}

.warning {
	color: $k-warning !important;
}

.bkg-warning {
	background-color: $k-warning !important;
}

.bkg-warning-light {
	background-color: $k-warning-light !important;
}

.bkg-warning-very-light {
	background-color: $k-warning-very-light !important;
}

.bkg-warning-transparent {
	background-color: $k-warning-transparent !important;
}

.border-warning {
	border: $border-XS solid $k-warning !important;
}

.success {
	color: $k-success !important;
}

.bkg-success {
	background-color: $k-success !important;
}

.bkg-success-light {
	background-color: $k-success-light !important;
}

.bkg-success-very-light {
	background-color: $k-success-very-light !important;
}

.bkg-success-transparent {
	background-color: $k-success-transparent !important;
}

.border-success {
	border: $border-XS solid $k-success !important;
}

.white {
	color: white;
}

.bkg-white {
	background-color: white;
}

.bkg-transparent {
	background-color: transparent !important;
}

.bkg-grey {
	background-color: $k-grey;
}

.bkg-light-grey-3 {
	background-color: $k-lightgrey-3;
}

.bkg-light-grey-2 {
	background-color: $k-lightgrey-2;
}

.bkg-light-grey-2-imp {
	background-color: $k-lightgrey-2 !important;
}

.bkg-light-grey {
	background-color: $k-lightgrey;
}

.grey {
	color: $k-grey !important;
}

.grey-border {
	color: $k-bordergrey !important;
}

.medium-grey {
	color: $k-lightgrey-3 !important;
}

.dark-grey {
	color: $k-darkgrey !important;
}

.l-h-n {
	line-height: normal !important;
}

.pointer {
	cursor: pointer;
}

.container-no-margin {
	margin: -20px -20px 0 -20px;
}

/**
 * Material Design Checkbox customization
 */

// Change material checkbox tick color
.mdc-checkbox__native-control:checked~.mdc-checkbox__background .mdc-checkbox__checkmark-path {
	color: #fff;
}

// Change material checkbox ripple background color
.mat-mdc-checkbox .mdc-checkbox__ripple {
	background-color: $k-bordergrey;
}

// Change material checkbox background border color
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:indeterminate):not(:checked)~.mdc-checkbox__background {
	border-color: $k-bordergrey !important;
}

// Change material dialog surface border radius
.mat-mdc-dialog-surface {
	&.mdc-dialog__surface {
		border-radius: 15px !important;
	}
}

// Change material Mat Ripple
.mat-ripple-element {
	background-color: rgba(0, 0, 0, 0.03);
}

.k-menu {
	border-radius: 4px;
	border: 1px solid rgb(225, 230, 234);
	box-shadow: rgb(63 71 79 / 15%) 0px 4px 10px;
	font-size: 0.8rem !important;
	color: $k-primary;
	min-height: unset !important;

	.mat-menu-content, .mat-list-text {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 0.8rem !important;
		color: $k-primary !important;
		font-weight: 700;
	}

	.mat-menu-panel {
		min-height: unset !important;
	}
}

.k-new-menu {
	border: 1px solid rgb(225, 230, 234);
	box-shadow: rgb(63 71 79 / 15%) 0px 4px 10px;
	font-size: 0.8rem !important;
	color: $k-primary;
	min-height: unset !important;

	.mat-menu-content, .mat-list-text {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 0.8rem !important;
		color: $k-primary !important;
		font-weight: 700;
	}

	.mat-menu-panel {
		min-height: unset !important;
	}
}

.k-menu-large {
	.mat-menu-panel {
		max-width: none !important;
	}
}

.k-menu-small {
	min-width: 0 !important;
}

// Shine animation
@keyframes shine {
	to {
		background-position-x: -200%;
	}
}

.shine-loader {
	animation: 1.5s shine linear infinite;
	background: $k-lightgrey;
	background: linear-gradient(110deg, $k-lightgrey 8%, #f5f5f5 18%, $k-lightgrey 33%);
	border-radius: 5px;
	background-size: 200% 100%;
}

// Splash screen animations

@-webkit-keyframes bounce-in-top {
	0% {
		-webkit-transform: translateY(-500px);
		transform: translateY(-500px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}
	38% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
		opacity: 1;
	}
	55% {
		-webkit-transform: translateY(-65px);
		transform: translateY(-65px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	72% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	81% {
		-webkit-transform: translateY(-28px);
		transform: translateY(-28px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	90% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	95% {
		-webkit-transform: translateY(-8px);
		transform: translateY(-8px);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}
// Check animation
.slide-in-fwd-center {
	-webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-fwd-center {
	0% {
		-webkit-transform: translateZ(-1400px);
		transform: translateZ(-1400px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}
@keyframes slide-in-fwd-center {
	0% {
		-webkit-transform: translateZ(-1400px);
		transform: translateZ(-1400px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
		opacity: 1;
	}
}

.splash-screen {
	position: fixed; /* or absolute */
	top: calc(50% - 100px);
	left: calc(50% - 100px);

	.k-small {
		font-size: 2.5rem;
		letter-spacing: -0.1rem;
	}
}

.header {
	background-color: $k-lightgrey-2;
}

.title {
	font-size: $font-size-L;
	font-weight: 700;
}

.subtitle {
	color: $k-darkgrey;
	white-space: pre-line
}

.pre-line {
	white-space: pre-line
}

.slider-content {
	height: calc(100vh - 35px - 20px);
	overflow-y: auto;
}

.mat-tab-labels {
	background-color: #FFFFFF !important;
}

.slider-header {
	height: 35px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: #FFFFFF !important;
	border-bottom: 1px solid $k-lightgrey-3;

	.close-icon {
		font-size: 1.1rem;
		text-align: center;
		background-color: rgba(198, 197, 197, 0.6);
		padding: 5px 3px 0px 3px;
		border-radius: 50%;
		color: #6E6E82;
		cursor: pointer;
	}
}

.tab {
	text-transform: uppercase !important;
	color: $k-accent !important;
	font-size: $font-size-S;
	font-weight: 700;
	padding: 0 10px;
}

.success-snackbar {
	color: #0f5132;
	background-color: #d1e7dd;
	border-color: #badbcc;
	box-shadow: 0px 0px 15px #eaeaea;
}

.failed-snackbar {
	color: $k-warn;
	background-color: #fce1ed;
	border-color: #f5adcb;
	box-shadow: 0px 0px 15px #eaeaea;
}

.secondary-snackbar {
	color: #41464b;
	background-color: white;
	border: solid 1px rgba(0, 0, 0, 0.05);
	box-shadow: 0px 0px 15px #eaeaea;;
}

.square-icon-m {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $k-lightgrey-3 !important;
	padding: 6px;
	border-radius: 4px;
	font-size: 0.9rem;
	line-height: 24px;
	text-align: center;
	color: $k-primary !important;
	height: 24px;
	width: 24px;

	&.success {
		background-color: $k-success-light !important;
		color: $k-success !important;
	}
}

.square-icon {
	background: $k-lightgrey-3 !important;
	padding: 3px;
	border-radius: 4px;
	font-size: 0.9rem;
	line-height: 24px;
	text-align: center;
	color: $k-primary !important;

	&.success {
		background-color: $k-success-light !important;
		color: $k-success !important;
	}

	&.primary-background {
		background-color: $k-primary !important;
		color: white !important;
	}
	&.border {
		background-color: transparent !important;
		padding: 0;
		line-height: 0;
		overflow: hidden;
	}
}
.square-icon-2 {
	background: #F7F8FA;
	padding: 3px;
	border-radius: 9px;
	font-size: 0.9rem;
	line-height: 24px;
	text-align: center;
	user-select: none; /* standard syntax */
	-webkit-user-select: none; /* webkit (safari, chrome) browsers */
	-moz-user-select: none; /* mozilla browsers */
	-khtml-user-select: none; /* webkit (konqueror) browsers */
	-ms-user-select: none; /* IE10+ */
	border: 1px solid rgba(61, 192, 255, 0);
	transition: all 0.3s;
	img:not(.cog-active):not(.comment-active) {
		filter: invert(80%) sepia(9%) saturate(316%) hue-rotate(201deg) brightness(95%) contrast(80%);
		transition: all 0.3s;
	}

	&.darken-icon {
		img:not(.cog-active):not(.comment-active) {
			filter: invert(14%) sepia(14%) saturate(1835%) hue-rotate(158deg) brightness(93%) contrast(102%);
			transition: all 0.3s;
		}
	}
}

.square-icon-2:hover {
	border: 1px solid $k-accent;
	img:not(.cog-active):not(.comment-active) {
		filter: invert(60%) sepia(91%) saturate(963%) hue-rotate(170deg) brightness(101%) contrast(101%);
	}
}

.square-icon-primary {
	background-color: $k-primary;
	color: white;
	border-radius: 4px;
	font-size: 0.9rem;
	line-height: 24px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 14px;
		height: 14px;
		object-fit: contain;
	}
}

.square-icon-accent {
	background-color: $k-accent-light;
	color: $k-accent;
	border-radius: 7px;
	font-size: 0.9rem;
	line-height: 24px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 16px;
		height: 16px;
		object-fit: contain;
		@extend .filter-accent;
	}
}

.square-icon-grey {
	background-color: $k-lightgrey-2;
	color: $k-grey;
	border-radius: 4px;
	font-size: 0.9rem;
	line-height: 24px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 20px;
		height: 20px;
		object-fit: contain;
		@extend .filter-grey;
	}
}


.square-icon-s {
	background: $k-lightgrey-3 !important;
	padding: 1px;
	border-radius: 4px;
	font-size: 12px;
	height: 20px !important;
	width: 20px !important;
	line-height: 20px !important;
	text-align: center;
	color: $k-primary !important;

	&.success {
		background-color: $k-success-light !important;
		color: $k-success !important;
	}

	&.accent {
		background-color: $k-accent !important;
		color: white !important;
	}

	&.primary {
		background-color: $k-primary !important;
		color: white !important;
	}
}

.circle-icon {
	background: $k-primary;
	padding: 3px;
	border-radius: 50%;
	font-size: 0.9rem;
	line-height: 24px;
	text-align: center;
	color: white;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.circle-icon-grey {
	background: $k-grey !important;
}

.circle-icon-warn {
	background: $k-warn !important;
}


.circle-icon-gradient {
	background: linear-gradient(45deg, rgba(223,52,110,1) 0%, rgba(33,154,212,1) 100%) !important;
}

.circle-icon-medium-grey {
	background: $k-lightgrey-3 !important;
}

.circle-icon-accent {
	background: $k-accent !important;
}

.circle-icon-small {
	height: 18px;
	width: 18px;
	background: $k-primary;
	border-radius: 50%;
	line-height: 18px;
	text-align: center;
	color: white;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	//margin-right: 3px;
}


.badge {
	display: inline-table;
	vertical-align: middle;
	width: 20px;
	height: 20px;
	background-color: $k-lightgrey-3;
	border-radius: 50%;
	margin-left: 4px;
	margin-top: -2px;
	color: $k-darkgrey;
}

.orange-badge {
	background-color: $k-warning-light !important;
	color: $k-warning !important;
}

.badge-display {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

// Backdrop override
.mat-drawer-backdrop {
	&.mat-drawer-shown {
		background-color: rgba(0, 0, 0, 0.2) !important;
	}
}

// Side bar override
.mat-drawer-side {
	border-right: 1px solid $k-lightgrey-3;
}

// Global button override

.mat-form-field-appearance-outline .mat-form-field-outline > * {
	color: $k-lightgrey-3;
	background-color: $k-lightgrey-2;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick > * {
	border-color: $k-lightgrey-3;
	border-width: 1px;
	background-color: #fff;
	border-bottom-width: 2px;
	border-bottom-color: $k-accent;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick > * {
	border-color: $k-lightgrey-3;
	border-width: 1px;
	background-color: #fff;
	border-bottom-width: 2px;
	border-bottom-color: $k-warn;
}

.mat-form-field-infix {
	border-top: 0.24em solid transparent !important;
}

.no-infix .mat-form-field-infix {
	border-top: 0.84375em solid transparent !important;
}

.mat-form-field-prefix {
	top: 0.35em !important;
}

.icon-infix .mat-form-field-prefix {
   top: 0.25em !important;
}

.mat-form-field-suffix {
	top: 0.35em !important;
}

.form-field-small {
	.mat-form-field-infix {
		padding: 7px !important;
	}
}

.mat-button-s {
	line-height: 24px !important;
}

// Dialog
.mat-dialog-container {
	background-color: #fff !important;
	border-radius: 15px !important;
}


.validate-button {
	.mat-button-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.mat-progress-spinner circle, .mat-spinner circle {
		stroke: #FFFFFF;
	}
}

.icon {
	fill: $k-grey;
}

.filter-primary {
	filter: $k-primary-filter;
}

.filter-accent {
	filter: $k-accent-filter;
}

.filter-accent-secondary {
	filter: $k-accent-secondary-filter;
}

.filter-warn {
	filter: $k-warn-filter;
}

.filter-success {
	filter: $k-success-filter;
}

.filter-warning {
	filter: $k-warning-filter;
}

.filter-danger {
	filter: $k-error-filter;
}

.filter-grey {
	filter: $k-grey-filter;
}

.filter-grey-imp {
	filter: $k-grey-filter !important;
}

.filter-lightgrey {
	filter: $k-lightgrey-filter;
}

.filter-white {
	filter: $k-white-filter;
}

.filter-grayscale {
	filter: grayscale(1);
}

.list {
	background-color: white;
	border-radius: 10px;
	border: 1px solid $k-lightgrey-3;
	padding: 5px 0;

	.list-row {
		display: grid;
		grid-template-columns: 60px calc(20% - 12px) calc(15% - 12px) calc(35% - 12px) calc(20% - 12px) calc(10% - 12px);
		height: 70px;
		vertical-align: middle;
		border-top: 1px solid $k-lightgrey-3;

		.cell {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.mat-form-field-wrapper {
				padding-bottom: 0;
			}
		}

		.cell.centered {
			justify-content: center !important;
		}

		.cell.end {
			justify-content: flex-end !important;
		}

	}

	.list-row.no-border-top {
		border-top: none !important;

	}
}

.mat-tooltip-panel-right{
	&::after {
		transform: rotate(45deg);
		position: absolute;
		content: '';
		background-color: #fff;
		left: -4px;
		top: calc(50% - 5px);
		z-index: 10!important;
		width: 10px;
		height: 10px;
		border-left: 1px solid rgb(0, 0, 0, 11%);
		border-bottom: 1px solid rgb(0, 0, 0, 11%);
	}
}

.mat-tooltip-panel-above {
	&::after {
		transform: rotate(45deg);
		position: absolute;
		content: '';
		background-color: #fff;
		bottom: 8px;
		left: calc(50% - 5px);
		z-index: 10!important;
		width: 10px;
		height: 10px;
		border-right: 1px solid rgb(0, 0, 0, 11%);
		border-bottom: 1px solid rgb(0, 0, 0, 11%);
	}
}

.mat-tooltip-panel-below {
	&::after {
		transform: rotate(45deg);
		position: absolute;
		content: '';
		background-color: #fff;
		top: 8px;
		left: calc(50% - 5px);
		z-index: 10!important;
		width: 10px;
		height: 10px;
		border-left: 1px solid rgb(0, 0, 0, 11%);
		border-top: 1px solid rgb(0, 0, 0, 11%);
	}
}

.mat-tooltip {
	background-color: #FFFFFF;
	color: $k-primary !important;
	border: 0 !important;
	box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 20%), 0 0 0 1px rgba(0, 0, 0, 5%) !important;
	z-index: 1 !important;
	overflow: visible;
	position: relative;
	font-weight: 700;
	min-width: 0!important;
}

.mat-tooltip:before {
	margin-top: -6px;
	border-color: transparent #e1e6ea transparent transparent;
}

.main-container {
	background-color: $k-lightgrey-2;
}

.table {
	border: 1px solid $k-lightgrey-3;
	border-radius: 15px;
	overflow: hidden;

	td.mat-cell {
		border-color: $k-lightgrey-3;
		padding-top: 5px !important;
		padding-bottom: 5px !important;
		vertical-align: middle;
	}

	th.mat-header-cell {
		border-color: $k-lightgrey-3;
	}

	.mat-footer-row {
		border-bottom: none !important;
	}

	.mat-footer-cell {
		border-bottom: none;
		color: inherit;
	}

	tr.hidden-detail-row {
		height: 0;
		display: none;
	}
	tr.mat-row {
		height: 60px;
	}

	tr.mat-header-row {
		height: 35px;
	}

	th.mat-header-cell {
		padding-bottom: 5px !important;
	}
	.mat-sort-header-arrow {
		color: $k-primary !important;
	}
}

.icon-10 {
	font-size: 10px !important;
	height: 10px !important;
	line-height: 10px !important;
	vertical-align: middle;
	width: 10px !important;
}

.icon-12 {
	font-size: 12px !important;
	height: 12px !important;
	line-height: 12px !important;
	vertical-align: middle;
	width: 12px !important;
}

.icon-14 {
	font-size: 14px;
	height: 14px !important;
	line-height: 14px !important;
	vertical-align: middle;
	width: 14px !important;
}

.icon-16 {
	font-size: 16px;
	height: 16px !important;
	line-height: 16px !important;
	vertical-align: middle;
	width: 16px !important;
}

.icon-18 {
	font-size: 18px;
	height: 18px !important;
	line-height: 18px !important;
	vertical-align: middle;
	width: 18px !important;
}

.icon-20 {
	font-size: 20px;
	height: 20px !important;
	line-height: 20px !important;
	vertical-align: middle;
	width: 20px !important;
}

.icon-22 {
	font-size: 22px;
	height: 22px !important;
	line-height: 22px !important;
	vertical-align: middle;
	width: 22px !important;
}


.icon-26 {
	font-size: 26px;
	height: 26px !important;
	line-height: 26px !important;
	vertical-align: middle;
	width: 26px !important;
}

.icon-32 {
	font-size: 32px;
	height: 32px !important;
	line-height: 32px !important;
	vertical-align: middle;
	width: 32px !important;
}


// Grid
.span2 {
	grid-column: span 2;
}

.span3 {
	grid-column: span 3;
}

.span4 {
	grid-column: span 4;
}

// Mat tabs
.mat-tab-label {
	background-color: transparent;
	.tab {
		color: $k-darkgrey !important;
	}
}

/* Styles for the active tabs label */
.mat-tab-label.mat-tab-label-active {
	background-color: transparent;
	opacity: 1;
	.tab {
		color: $k-accent !important;
	}
}

/* Styles for the ink bar */
.mat-ink-bar {
	background-color: $k-accent !important;
}

.button-group {
	font-size: 0.8rem;

	.mat-button-toggle-label-content {
		line-height: 36px !important;
	}

	.mat-button-toggle-checked {
		background-color: #fff;
	}
}

/* Mat input placeholder */
.mat-focused .placeholder {
	color: $k-grey !important;
}

.mat-form-field {
	.mat-form-field-label {
		color: $k-grey;
	}
	color: $k-primary;
}

input {
	line-height: $font-size-M;
	font-size: $font-size-M;
	font-family: 'proxima-nova', sans-serif;
}

input::placeholder {
	font-size: $font-size-M;
	font-family: 'proxima-nova', sans-serif;
	color: $k-grey !important;
}

/* Mat select */
mat-select {
	.mat-select-arrow-wrapper {
		display: flex !important;
		align-items: flex-end;
	}

	.mat-select-value-text, .mat-select-arrow {
		color: $k-primary !important;
	}
}

mat-option {
	.mat-option-text {
		color: $k-primary !important;
	}
}

mat-tab-header {
	.mat-tab-label-container {
		.mat-tab-labels {
			.mat-tab-label {
				height: 36px;
				padding: 0 0 !important;
				min-width: 110px;
				margin-left: 25px;
			}
		}
	}
}

@function hexToRGB($hex) {
	@return red($hex), green($hex), blue($hex);
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-visible {
	overflow: visible;
}

.overflow-y {
	overflow-y: auto;
}

.overflow-x {
	overflow-x: auto;
}

.overflow-x-y {
	overflow: auto;
}

.split {
	height: 100%;
	width: 50%;
	position: fixed;
	z-index: 1;
	top: 0;
	overflow-x: hidden;
}

.left {
	left: 0;
	color: black;
}

.right {
	color: #FFFFFF;
	right: 0;
	background-color: #3DC0FF;
}

.squares {
	position: relative;
}

.square-1 {
	z-index: 1;
	height: 600px;
	width: 600px;
	left: 340px;
	top: -450px;
	position: absolute;
	background: rgba(255, 255, 255, 0.23);
	border-radius: 78px;
	transform: rotate(-45deg);
}

.img-servers {
	width: 350px;
}

.square-2 {
	height: 600px;
	width: 600px;
	top: 50px;
	left: 850px;
	position: absolute;
	background: rgba(255, 255, 255, 0.23);
	border-radius: 78px;
	transform: rotate(-45deg);
}

.learn-more {
	transition: all 200ms;
	border: 1px solid #FFFFFF;
	color: #FFFFFF;
	background-color: #3DC0FF;
	border-radius: 6px;
	padding: 10px 20px;
}

.learn-more:hover {
	background-color: #FFFFFF;
	color: #3DC0FF;
}

.form-container {
	box-sizing: content-box;
	width: calc(100% - 500px);
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
}

.logo {
	margin-top: 10px;
	margin-left: 10px;
}

.subtitle {
	margin-bottom: 10px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #8692A6;
}

.identity-federators {
	margin-top: 40px;
	margin-bottom: 40px;
	display: flex;
	align-self: center;
	justify-content: space-evenly;
}

.hr-text {
	line-height: 1em;
	font-size: 10px;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: .5;
	&:before {
		content: '';
		background: #F5F5F5;
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 1px;
	}
	&:after {
		content: attr(data-content);
		position: relative;
		display: inline-block;
		color: black;

		padding: 0 .5em;
		line-height: 1.5em;
		color: #818078;
		background-color: #fcfcfa;
	}
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 10px;
}

.column {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
}

.input-div {
	display: flex;
	flex-wrap: wrap;
}

.justify-flex-end {
	justify-content: flex-end;
}

.display-block {
	display:block;
}

.input-signup {
	transition: all 500ms;
	height: 48px;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	border: solid 1px #8692A6;
	border-radius: 6px;
	outline: none;
	color: #696F79;
	font-family: 'proxima-nova', sans-serif;
	font-size: 14px;
}

.password-eye {
	display: flex;
	float: right;
	position: relative;
	flex-direction: row-reverse;
	top: -58px;
	right: 10px;
}

.cross {
	position: relative;
	top: 44px;
	left: 10px;
}

.check {
	position: relative;
	top: 44px;
	left: 10px;
}

.input-label {
	margin-bottom: 10px;
	color: #696F79;
	font-size: 13px;
}

.flex-between {
	justify-content: space-between;
}

.checkbox-label {
	color: #696F79;
	font-size: 13px;
	margin-left: 5px;
}

.forgot-password {
	color: #696F79;
	font-size: 13px;
}

.forgot-password:hover {
	transition: color 300ms;
	color: #3DC0FF;
}

.input-signup:focus {
	border-color: #3DC0FF;
}

.input-signup:hover {
	transition: background-color ease-out 300ms;
	background-color: #FAFAFC;
}

.continue-button {
	width: 100%;
	height: 50px;
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: #3DC0FF;
	border-radius: 6px;
	color: #FFFFFF;
	border: solid 1px #3DC0FF;
	outline: none;
	cursor: pointer;
	position: relative;
	justify-content: center;
	font-family: 'proxima-nova', sans-serif;
}

.continue-button.hovered {
	animation: bounce-out 300ms forwards cubic-bezier(.77,-0.2,.38,1);
}

.continue-button:not([disabled]):hover {
	transition: background-color 300ms;
	animation: bounce-in 300ms forwards cubic-bezier(.77,-0.2,.38,1);
	background-color: #3BBAF7;
}

@keyframes bounce-in {
	from { width: 100%; }
	to { width: 95% }
}

@keyframes bounce-out {
	from { width: 95%; }
	to { width: 100% }
}

.sub-button {
	font-size: 13px;
	text-align: center;
	line-height: 19px;
	color: #696F79;
}

.terms {
	color: #3DC0FF;
	text-decoration: none;
}

.error {
	transition: all 200ms;
	border-color: #FF5555;
}

.text-red {
	transition: color 500ms;
	color: #FF5555;
}

.p-error {
	font-size: 13px;
	font-weight: 500;
}

.error:focus {
	border-color: #FF5555;
}

.inactive {
	transition: all 200ms;
	background-color: #F2F2F8;
	border-color: #F2F2F8;
	color: #8692A6;
	cursor: auto;
}

.disp-i {
	display: inline;
}

.justify-center {
	justify-content: center;
}

.success {
	color: $k-success;
	font-size: 13px;
	font-weight: 500;
}

.lds-ring div {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	position: absolute;
	width: 20px;
	height: 20px;
	border: 3px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@media only screen and (max-width : 1800px) {
	.form-container {
		width: calc(100% - 400px);
	}
}

@media only screen and (max-width : 1500px) {
	.form-container {
		width: calc(100% - 200px);
	}

	.img-servers {
		width: 250px;
	}
}

@media only screen and (max-width : 1150px) {
	.right {
		display: none;
	}

	.left {
		width: 100%;
	}

	.logo {
		margin-top: 40px;
		margin-left: 0px;
		display: flex;
		justify-content: center;
		align-self: center;
	}

	.flex-dir {
		flex-direction: column;
	}
}

@media only screen and (max-width: 968px) {
	.hr-text {
		display: none;
	}
}

@media only screen and (max-width : 600px) {
	.form-container {
		width: calc(100% - 50px);
		margin-top: 20px;
	}
}

@media only screen and (max-width: 400px) {
	.forgot-password {
		font-size: 11px;
	}

	.checkbox-label {
		font-size: 11px;
	}
}

@media only screen and (max-width: 340px) {
	.forgot-password {
		font-size: 9px;
	}

	.checkbox-label {
		font-size: 9px;
	}
}

button.mat-accent:not([disabled]) {
	color: white !important;
}

.chips-success {
	color: $k-success;
	background-color: $k-success-light;
}

.chips-warning {
	background-color: $k-warning-light;
	color: $k-warning;
}

.chips-danger {
	background-color: $k-error-light;
	color: $k-error;
}


// Drawer override
.mat-drawer {
	border-radius: 9px 0px 0px 9px !important;
}

.svg-24 {
	width: 24px;
	height: 24px;
}

.radius-10 {
	border-radius: 10px;
}
.radius-half {
	border-radius: 50%;
}

.white-border {
	border: 1px solid white;
}

.widget-group {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
	.one {
		width: 100%;
		grid-column: 1;
		grid-row: 1;
	}

	.two {
		width: 100%;
		grid-column: 2;
		grid-row: 1;
	}

	.three {
		width: 100%;
		grid-column: 3;
		grid-row: 1;
	}

	.full-width-widget {
		background: #FFFFFF;
		border-radius: 15px;
		color: $k-primary;
		border: 1px solid $k-lightgrey-3;
		transition: all .3s;
		position: relative;
		height: calc(132px - (20px / 2));
		overflow: hidden;
		.widget-plan-disabled {
			width: 100%;
			height: calc(132px - (20px / 2));
			position: absolute;
			z-index: 100;
			border-radius: 15px;
			//background: linear-gradient(0deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0.4) 70%, rgb(255, 255, 255, 0.1) 100%);
			background: linear-gradient(0deg, rgb(240, 240, 246) 40%, rgba(240, 240, 246, 0.48) 70%, rgb(255, 255, 255, 0.1) 100%);
		}
	}


}

.bg-rounded {
	background: #FFFFFF;
	border-radius: 15px;
	border: 0.5px solid $k-bordergrey;
}

.bg-rounded-error {
	background: #FF55551A;
	border-radius: 15px;
	border: 1px solid $k-error;
}

.list-icon {
	width: 40px;
	height: 40px;
	border-radius: 7px;
	background-color: $k-accent-light;
}

.custom-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
	border-radius: 10px;
}

.custom-dialog .mdc-dialog__surface {
	overflow-y: unset;
}

.flex {
	display: flex;
}

.flex-wrap {
	display: flex;
	flex-wrap: wrap;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-align-center {
	display: flex;
	align-items: center;
}

.flex-justify-center {
	display: flex;
	justify-content: center;
}

.flex-space-between {
	display: flex;
	justify-content: space-between;
}

.flex-space-evenly {
	display: flex;
	justify-content: space-evenly;
}

.flex-space-around {
	display: flex;
	justify-content: space-around;
}

.flex-justify-start {
	display: flex;
	justify-content: flex-start;
}

.flex-justify-end {
	display: flex;
	justify-content: flex-end;
}

.flex-align-start {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
}

.flex-align-end {
	display: flex;
	align-items: flex-end;
	align-content: flex-end;
}

.flex-start {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	justify-content: flex-start;
}

.flex-end {
	display: flex;
	align-items: flex-end;
	align-content: flex-end;
	justify-content: flex-end;
}

.f1 {
	flex: 1;
}
.f2 {
	flex: 2;
}
.f3 {
	flex: 3;
}
.f4 {
	flex: 4;
}
.f5 {
	flex: 5;
}
.f6 {
	flex: 6;
}
.f7 {
	flex: 7;
}
.f8 {
	flex: 8;
}
.f9 {
	flex: 9;
}
.f10 {
	flex: 10;
}

.text-overflow-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.border-box {
	box-sizing: border-box;
}

.border-box-full {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
}

.svg-sizing {
	svg {
		@extend .border-box-full;
		border-radius: 15px;
	}
}

mat-tooltip-component {
	.mat-tooltip-handset {
		margin: 14px;
	}
}

.visibility-hidden {
	visibility: hidden;
}

.none-events {
	pointer-events: none;
}

.w-10 {
	width: 10%;
}
.w-15 {
	width: 15%;
}
.w-20 {
	width: 20%;
}
.w-25 {
	width: 25%;
}
.w-30 {
	width: 30%;
}
.w-35 {
	width: 35%;
}
.w-40 {
	width: 40%;
}
.w-45 {
	width: 45%;
}
.w-50 {
	width: 50%;
}
.w-60 {
	width: 60%;
}
.w-70 {
	width: 70%;
}
.w-75 {
	width: 75%;
}
.w-80 {
	width: 80%;
}
.w-85 {
	width: 85%;
}
.w-90 {
	width: 90%;
}
.w-95 {
	width: 95%;
}
.w-100 {
	width: 100%;
}

.w-fit-content {
	width: fit-content;
}

.w-100-imp {
	width: 100% !important;
}

.h-10 {
	height: 10%;
}
.h-20 {
	height: 20%;
}
.h-25 {
	height: 25%;
}
.h-30 {
	height: 30%;
}
.h-40 {
	height: 40%;
}
.h-50 {
	height: 50%;
}
.h-60 {
	height: 60%;
}
.h-70 {
	height: 70%;
}
.h-75 {
	height: 75%;
}
.h-80 {
	height: 80%;
}
.h-90 {
	height: 90%;
}
.h-100 {
	height: 100%;
}
.h-100-imp {
	height: 100% !important;
}

.h-auto {
	height: auto;
}

.h-100vh {
	height: 100vh;
}

.gap-5 {
	gap: 5px;
}
.gap-10 {
	gap: 10px;
}
.gap-15 {
	gap: 15px;
}
.gap-20 {
	gap: 20px;
}
.gap-25 {
	gap: 25px;
}
.gap-30 {
	gap: 25px;
}

.align-self-center-z2 {
	align-self: center;
	z-index: 2;
}

.fix-edit-margin {
	img {
		margin: -2px -2px 0 0;
	}
}

.img-size-70 {
	img {
		width: 70% !important;
		height: 70% !important;
	}
}

.img-size-50 {
	img {
		width: 50% !important;
		height: 50% !important;
	}
}

.img-size-40 {
	img {
		width: 40% !important;
		height: 40% !important;
	}
}

.img-size-30 {
	img {
		width: 30% !important;
		height: 30% !important;
	}
}

.img-icon-arrow-top {
	img {
		transform: rotate(-90deg);
		margin-bottom: 2px;
	}
}

.img-icon-arrow-bottom {
	img {
		transform: rotate(90deg);
		margin-top: 2px;
	}
}

.greyed-hover {
	transition: background 0.3s;
	&:hover {
		background: $k-lightgrey-2 !important;
	}
}

.greyed-hover-2 {
	transition: background 0.3s;
	&:hover {
		background: $k-lightgrey !important;
	}
}

.accent-hover {
	transition: color 300ms;
	&:hover {
		color: $k-accent;
	}
}

.underlined-hover:hover {
	text-decoration: underline;
}

.settings-separator {
	border-top: 1px solid $k-lightgrey-3;
	border-left: none;
	border-bottom: none;
	border-right: none;
}

.border-white {
	border: $border-S solid white !important;
}

.border-grey {
	border: $border-S solid $k-bordergrey !important;
}

.border-grey-XS {
	border: $border-XS solid $k-bordergrey !important;
}

.border-grey-top {
	border-top: $border-S solid $k-bordergrey !important;
}

.border-grey-right {
	border-right: $border-S solid	$k-bordergrey !important;
}

.border-grey-bottom {
	border-bottom: $border-S solid $k-bordergrey !important;
}

.border-grey-left {
	border-left: $border-S solid $k-bordergrey !important;
}

.border-light-grey {
	border: $border-S solid $k-lightgrey-3 !important;
}

.border-light-grey-top {
	border-top: $border-S solid $k-lightgrey-3 !important;
}

.border-light-grey-right {
	border-right: $border-S solid $k-lightgrey-3 !important;
}

.border-light-grey-bottom {
	border-bottom: $border-S solid $k-lightgrey-3 !important;
}

.border-light-grey-left {
	border-left: $border-S solid $k-lightgrey-3 !important;
}

.border-dashed {
	border-style: dashed !important;
}

.b-radius-6 {
	border-radius: 6px !important;
}

.b-radius-left-6 {
	border-radius: 6px 0 0 6px !important;
}

.b-radius-right-6 {
	border-radius: 0 6px 6px 0 !important;
}

.b-radius-top-6 {
	border-radius: 6px 6px 0 0 !important;
}

.b-radius-bottom-6 {
	border-radius: 0 0 6px 6px !important;
}

.b-radius-9 {
	border-radius: 9px !important;
}

.b-radius-left-9 {
	border-radius: 9px 0 0 9px !important;
}

.b-radius-right-9 {
	border-radius: 0 9px 9px 0 !important;
}

.b-radius-top-9 {
	border-radius: 9px 9px 0 0 !important;
}

.b-radius-bottom-9 {
	border-radius: 0 0 9px 9px !important;
}

.b-radius-14 {
	border-radius: 14px !important;
}

.b-radius-left-14 {
	border-radius: 14px 0 0 14px !important;
}

.b-radius-right-14 {
	border-radius: 0 14px 14px 0 !important;
}

.b-radius-top-14 {
	border-radius: 14px 14px 0 0 !important;
}

.b-radius-bottom-14 {
	border-radius: 0 0 14px 14px !important;
}

.b-radius-half {
	border-radius: 50% !important;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.p-relative {
	position: relative;
}

.p-absolute {
	position: absolute;
}

.p-sticky {
	position: sticky;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
::-webkit-scrollbar {
	background-color: transparent;
	width: 8px;
	height: 8px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
	background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
	background-color: #babac0;
	border-radius: 16px;
	background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #a0a0a5;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
	display:none;
}

.radius-S {
	border-radius: $border-radius-S;
}
.radius-M {
	border-radius: $border-radius-M;
}
.radius-L {
	border-radius: $border-radius-L;
}
.radius-XL {
	border-radius: $border-radius-XL;
}
.horizontal-bar-S {
	$radius-S: $border-radius-S;
	$bar-size-S: calc($radius-S * 2);
	height: $bar-size-S;
	min-height: $bar-size-S;
	max-height: $bar-size-S;
	min-width: $bar-size-S;
	border-radius: $radius-S;
}
.horizontal-bar-M {
	$radius-M: $border-radius-M;
	$bar-size-M: calc($radius-M * 2);
	height: $bar-size-M;
	min-height: $bar-size-M;
	max-height: $bar-size-M;
	min-width: $bar-size-M;
	border-radius: $radius-M;
}
.horizontal-bar-L {
	$radius-L: $border-radius-L;
	$bar-size-L: calc($radius-L * 2);
	height: $bar-size-L;
	min-height: $bar-size-L;
	max-height: $bar-size-L;
	min-width: $bar-size-L;
	border-radius: $radius-L;
}
.horizontal-bar-XL {
	$radius-XL: $border-radius-XL;
	$bar-size-XL: calc($radius-XL * 2);
	height: $bar-size-XL;
	min-height: $bar-size-XL;
	max-height: $bar-size-XL;
	min-width: $bar-size-XL;
	border-radius: $radius-XL;
}
.vertical-bar-S {
	$radius-S: $border-radius-S;
	$bar-size-S: calc($radius-S * 4);
	width: $bar-size-S;
	min-width: $bar-size-S;
	max-width: $bar-size-S;
	min-height: $bar-size-S;
	border-radius: $radius-S;
}
.vertical-bar-M {
	$radius-M: $border-radius-M;
	$bar-size-M: calc($radius-M * 4);
	width: $bar-size-M;
	min-width: $bar-size-M;
	max-width: $bar-size-M;
	min-height: $bar-size-M;
	border-radius: $radius-M;
}
.vertical-bar-L {
	$radius-L: $border-radius-L;
	$bar-size-L: calc($radius-L * 4);
	width: $bar-size-L;
	min-width: $bar-size-L;
	max-width: $bar-size-L;
	min-height: $bar-size-L;
	border-radius: $radius-L;
}
.vertical-bar-XL {
	$radius-XL: $border-radius-XL;
	$bar-size-XL: calc($radius-XL * 4);
	width: $bar-size-XL;
	min-width: $bar-size-XL;
	max-width: $bar-size-XL;
	min-height: $bar-size-XL;
	border-radius: $radius-XL;
}
.font-XXL {
	// 31.2px
	font-size: $font-size-XXL;
	line-height: $line-height-XXL;
}
.font-XL {
	// 23.4px
	font-size: $font-size-XL;
	line-height: $line-height-XL;
}
.font-LL {
	// 19.5px
	font-size: $font-size-LL;
	line-height: $line-height-LL;
}
.font-L {
	// 16.25px
	font-size: $font-size-L;
	line-height: $line-height-L;
}
.font-MM {
	// 14.3px;
	font-size: $font-size-MM;
	line-height: $line-height-MM;
}
.font-M {
	// 13px;
	font-size: $font-size-M;
	line-height: $line-height-M;
}
.font-S {
	// 11.7px;
	font-size: $font-size-S;
	line-height: $line-height-S;
}
.font-XS {
	// 9.1px;
	font-size: $font-size-XS;
	line-height: $line-height-XS;
}
.gap-XXL {
	gap: $gap-XXL;
}
.gap-XL {
	gap: $gap-XL;
}
.gap-widget {
	gap: $gap-widget;
}
.gap-L {
	gap: $gap-L;
}
.gap-M {
	gap: $gap-M;
}
.gap-S {
	gap: $gap-S;
}
.gap-XS {
	gap: $gap-XS;
}
.pad-XXL {
	padding: $padding-XXL;
}
.pad-left-XXL {
	padding-left: $padding-XXL;
}
.pad-right-XXL {
	padding-right: $padding-XXL;
}
.pad-top-XXL {
	padding-top: $padding-XXL;
}
.pad-bottom-XXL {
	padding-bottom: $padding-XXL;
}
.pad-0-XXL {
	padding-left: $padding-XXL;
	padding-right: $padding-XXL;
}
.pad-XXL-0 {
	padding-top: $padding-XXL;
	padding-bottom: $padding-XXL;
}
.pad-XXL-XXL-0 {
	padding: $padding-XXL $padding-XXL 0;
}
.pad-XL {
	padding: $padding-XL;
}
.pad-left-XL {
	padding-left: $padding-XL;
}
.pad-right-XL {
	padding-right: $padding-XL;
}
.pad-top-XL {
	padding-top: $padding-XL;
}
.pad-bottom-XL {
	padding-bottom: $padding-XL;
}
.pad-0-XL {
	padding-left: $padding-XL;
	padding-right: $padding-XL;
}
.pad-XL-0 {
	padding-top: $padding-XL;
	padding-bottom: $padding-XL;
}
.pad-XL-XL-0 {
	padding: $padding-XL $padding-XL 0;
}
.pad-L {
	padding: $padding-L;
}
.pad-left-L {
	padding-left: $padding-L;
}
.pad-right-L {
	padding-right: $padding-L;
}
.pad-top-L {
	padding-top: $padding-L;
}
.pad-bottom-L {
	padding-bottom: $padding-L;
}
.pad-0-L {
	padding-left: $padding-L;
	padding-right: $padding-L;
}
.pad-L-0 {
	padding-top: $padding-L;
	padding-bottom: $padding-L;
}
.pad-L-L-0 {
	padding: $padding-L $padding-L 0;
}
.pad-M {
	padding: $padding-M;
}
.pad-left-M {
	padding-left: $padding-M;
}
.pad-right-M {
	padding-right: $padding-M;
}
.pad-top-M {
	padding-top: $padding-M;
}
.pad-bottom-M {
	padding-bottom: $padding-M;
}
.pad-0-M {
	padding-left: $padding-M;
	padding-right: $padding-M;
}
.pad-M-0 {
	padding-top: $padding-M;
	padding-bottom: $padding-M;
}
.pad-M-M-0 {
	padding: $padding-M $padding-M 0;
}
.pad-S {
	padding: $padding-S;
}
.pad-left-S {
	padding-left: $padding-S;
}
.pad-right-S {
	padding-right: $padding-S;
}
.pad-top-S {
	padding-top: $padding-S;
}
.pad-bottom-S {
	padding-bottom: $padding-S;
}
.pad-0-S {
	padding-left: $padding-S;
	padding-right: $padding-S;
}
.pad-S-0 {
	padding-top: $padding-S;
	padding-bottom: $padding-S;
}
.pad-S-S-0 {
	padding: $padding-S $padding-S 0;
}
.pad-XS {
	padding: $padding-XS;
}
.pad-left-XS {
	padding-left: $padding-XS;
}
.pad-right-XS {
	padding-right: $padding-XS;
}
.pad-top-XS {
	padding-top: $padding-XS;
}
.pad-bottom-XS {
	padding-bottom: $padding-XS;
}
.pad-0-XS {
	padding-left: $padding-XS;
	padding-right: $padding-XS;
}
.pad-XS-0 {
	padding-top: $padding-XS;
	padding-bottom: $padding-XS;
}
.pad-XS-XS-0 {
	padding: $padding-XS $padding-XS 0;
}
.pad-0 {
	padding: 0;
}
.pad-1 {
	padding: 1px;
}
.pad-0-imp {
	padding: 0 !important;
}

.greyed-out {
	color: $k-grey;
	opacity: 0.5;
}

.hr-document {
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 1px solid $k-lightgrey-3;
}

.month-picker .mat-calendar-period-button {
	pointer-events: none;
}

.month-picker .mat-calendar-arrow {
	display: none;
}

.prevent-select {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.rotate-45 {
	transform: rotate(45deg);
}

.rotate-90 {
	transform: rotate(90deg);
}

.rotate-180 {
	transform: rotate(180deg);
}

.add-url-to-app-modal-container .mat-mdc-dialog-container {
	animation: mat-dialog-animation 1.1s ease-in-out;
}

@keyframes mat-dialog-animation {
	0% {
		transform: translateY(150%);
	}

	70% {
		transform: translateY(150%);
	}

	100% {
		transform: translateY(0);
	}
}

app-tooltip {
	// /!\ DO NOT DELETE THIS /!\
	display: none;
}
app-mini-drawer {
	// /!\ DO NOT DELETE THIS /!\
	position: absolute;
}

.mat-datepicker-content {
	border-radius: 15px !important;

	.mat-calendar-body-selected.mat-calendar-body-today {
		box-shadow: none !important;
	}

	.mat-calendar-body-selected {
		background-color: $k-accent !important;
		color: white !important;
	}

	.mat-calendar-body-cell:not(.mat-calendar-body-disabled)>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
		transition: background-color 0.3s;
	}

	.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
		background-color: $k-lightgrey-2 !important;
	}

	.mat-calendar-header button.mat-calendar-period-button {
		text-transform: lowercase !important;

		span:first-letter {
			text-transform: uppercase !important;
		}
	}

	.mat-calendar-body-cell span {
		text-transform: lowercase !important;
	}

	.mat-calendar-body-label {
		text-transform: lowercase !important;

		&:first-letter {
			text-transform: uppercase !important;
		}
	}
}

.completion-anim {
	transition: width 0.3s cubic-bezier(.25,.75,.5,1.25);
}

