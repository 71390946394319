@import "src/variables";

@media screen and (max-width: $break-point-1) {
	$smaller-default-ratio: 0.75;
	$smaller-border-radius-S: calc($border-radius-S * $smaller-default-ratio);
	$smaller-border-radius-M: calc($border-radius-M * $smaller-default-ratio);
	$smaller-border-radius-L: calc($border-radius-L * $smaller-default-ratio);
	$smaller-font-size-XXL: calc($font-size-XXL * 0.9);
	$smaller-font-size-XL: calc($font-size-XL * 0.9);
	$smaller-font-size-L: calc($font-size-L * 0.9);
	$smaller-font-size-M: calc($font-size-M * 0.9);
	$smaller-font-size-S: calc($font-size-S * 0.9);
	$smaller-font-size-XS: calc($font-size-XS * 0.9);
	$smaller-gap-XXL: calc($gap-XXL * $smaller-default-ratio);
	$smaller-gap-XL: calc($gap-XL * $smaller-default-ratio);
	$smaller-gap-L: calc($gap-L * $smaller-default-ratio);
	$smaller-gap-M: calc($gap-M * $smaller-default-ratio);
	$smaller-gap-S: calc($gap-S * $smaller-default-ratio);
	$smaller-gap-XS: calc($gap-XS * $smaller-default-ratio);
	$smaller-padding-XXL: calc($padding-XXL * $smaller-default-ratio);
	$smaller-padding-XL: calc($padding-XL * $smaller-default-ratio);
	$smaller-padding-L: calc($padding-L * $smaller-default-ratio);
	$smaller-padding-M: calc($padding-M * $smaller-default-ratio);
	$smaller-padding-S: calc($padding-S * $smaller-default-ratio);
	$smaller-padding-XS: calc($padding-XS * $smaller-default-ratio);
	.r-radius-S {
		border-radius: $smaller-border-radius-S;
	}
	.r-radius-M {
		border-radius: $smaller-border-radius-M;
	}
	.r-radius-L {
		border-radius: $smaller-border-radius-L;
	}
	.r-horizontal-bar-S {
		$radius-S: $smaller-border-radius-S;
		$bar-size-S: calc($radius-S * 2);
		height: $bar-size-S;
		min-height: $bar-size-S;
		max-height: $bar-size-S;
		min-width: $bar-size-S;
		border-radius: $radius-S;
	}
	.r-horizontal-bar-M {
		$radius-M: $smaller-border-radius-M;
		$bar-size-M: calc($radius-M * 2);
		height: $bar-size-M;
		min-height: $bar-size-M;
		max-height: $bar-size-M;
		min-width: $bar-size-M;
		border-radius: $radius-M;
	}
	.r-horizontal-bar-L {
		$radius-L: $smaller-border-radius-L;
		$bar-size-L: calc($radius-L * 2);
		height: $bar-size-L;
		min-height: $bar-size-L;
		max-height: $bar-size-L;
		min-width: $bar-size-L;
		border-radius: $radius-L;
	}
	.r-vertical-bar-S {
		$radius-S: $smaller-border-radius-S;
		$bar-size-S: calc($radius-S * 4);
		width: $bar-size-S;
		min-width: $bar-size-S;
		max-width: $bar-size-S;
		min-height: $bar-size-S;
		border-radius: $radius-S;
	}
	.r-vertical-bar-M {
		$radius-M: $smaller-border-radius-M;
		$bar-size-M: calc($radius-M * 4);
		width: $bar-size-M;
		min-width: $bar-size-M;
		max-width: $bar-size-M;
		min-height: $bar-size-M;
		border-radius: $radius-M;
	}
	.r-vertical-bar-L {
		$radius-L: $smaller-border-radius-L;
		$bar-size-L: calc($radius-L * 4);
		width: $bar-size-L;
		min-width: $bar-size-L;
		max-width: $bar-size-L;
		min-height: $bar-size-L;
		border-radius: $radius-L;
	}
	.r-font-XXL {
		font-size: $smaller-font-size-XXL;
		line-height: $smaller-font-size-XXL;
	}
	.r-font-XL {
		font-size: $smaller-font-size-XL;
		line-height: $smaller-font-size-XL;
	}
	.r-font-L {
		font-size: $smaller-font-size-L;
		line-height: $smaller-font-size-L;
	}
	.r-font-M {
		font-size: $smaller-font-size-M;
		line-height: $smaller-font-size-M;
	}
	.r-font-S {
		font-size: $smaller-font-size-S;
		line-height: $smaller-font-size-S;
	}
	.r-font-XS {
		font-size: $smaller-font-size-XS;
		line-height: $smaller-font-size-XS;
	}
	.r-gap-XXL {
		gap: $smaller-gap-XXL;
	}
	.r-gap-XL {
		gap: $smaller-gap-XL;
	}
	.r-gap-L {
		gap: $smaller-gap-L;
	}
	.r-gap-M {
		gap: $smaller-gap-M;
	}
	.r-gap-S {
		gap: $smaller-gap-S;
	}
	.r-gap-XS {
		gap: $smaller-gap-XS;
	}
	.r-pad-XXL {
		padding: $smaller-padding-XXL;
	}
	.r-pad-left-XXL {
		padding-left: $smaller-padding-XXL;
	}
	.r-pad-right-XXL {
		padding-right: $smaller-padding-XXL;
	}
	.r-pad-top-XXL {
		padding-top: $smaller-padding-XXL;
	}
	.r-pad-bottom-XXL {
		padding-bottom: $smaller-padding-XXL;
	}
	.r-pad-0-XXL {
		padding-left: $smaller-padding-XXL;
		padding-right: $smaller-padding-XXL;
	}
	.r-pad-XXL-0 {
		padding-top: $smaller-padding-XXL;
		padding-bottom: $smaller-padding-XXL;
	}
	.r-pad-XXL-XXL-0 {
		padding: $smaller-padding-XXL $smaller-padding-XXL 0;
	}
	.r-pad-XL {
		padding: $smaller-padding-XL;
	}
	.r-pad-left-XL {
		padding-left: $smaller-padding-XL;
	}
	.r-pad-right-XL {
		padding-right: $smaller-padding-XL;
	}
	.r-pad-top-XL {
		padding-top: $smaller-padding-XL;
	}
	.r-pad-bottom-XL {
		padding-bottom: $smaller-padding-XL;
	}
	.r-pad-0-XL {
		padding-left: $smaller-padding-XL;
		padding-right: $smaller-padding-XL;
	}
	.r-pad-XL-0 {
		padding-top: $smaller-padding-XL;
		padding-bottom: $smaller-padding-XL;
	}
	.r-pad-XL-XL-0 {
		padding: $smaller-padding-XL $smaller-padding-XL 0;
	}
	.r-pad-L {
		padding: $smaller-padding-L;
	}
	.r-pad-left-L {
		padding-left: $smaller-padding-L;
	}
	.r-pad-right-L {
		padding-right: $smaller-padding-L;
	}
	.r-pad-top-L {
		padding-top: $smaller-padding-L;
	}
	.r-pad-bottom-L {
		padding-bottom: $smaller-padding-L;
	}
	.r-pad-0-L {
		padding-left: $smaller-padding-L;
		padding-right: $smaller-padding-L;
	}
	.r-pad-L-0 {
		padding-top: $smaller-padding-L;
		padding-bottom: $smaller-padding-L;
	}
	.r-pad-L-L-0 {
		padding: $smaller-padding-L $smaller-padding-L 0;
	}
	.r-pad-M {
		padding: $smaller-padding-M;
	}
	.r-pad-left-M {
		padding-left: $smaller-padding-M;
	}
	.r-pad-right-M {
		padding-right: $smaller-padding-M;
	}
	.r-pad-top-M {
		padding-top: $smaller-padding-M;
	}
	.r-pad-bottom-M {
		padding-bottom: $smaller-padding-M;
	}
	.r-pad-0-M {
		padding-left: $smaller-padding-M;
		padding-right: $smaller-padding-M;
	}
	.r-pad-M-0 {
		padding-top: $smaller-padding-M;
		padding-bottom: $smaller-padding-M;
	}
	.r-pad-M-M-0 {
		padding: $smaller-padding-M $smaller-padding-M 0;
	}
	.r-pad-S {
		padding: $smaller-padding-S;
	}
	.r-pad-left-S {
		padding-left: $smaller-padding-S;
	}
	.r-pad-right-S {
		padding-right: $smaller-padding-S;
	}
	.r-pad-top-S {
		padding-top: $smaller-padding-S;
	}
	.r-pad-bottom-S {
		padding-bottom: $smaller-padding-S;
	}
	.r-pad-0-S {
		padding-left: $smaller-padding-S;
		padding-right: $smaller-padding-S;
	}
	.r-pad-S-0 {
		padding-top: $smaller-padding-S;
		padding-bottom: $smaller-padding-S;
	}
	.r-pad-S-S-0 {
		padding: $smaller-padding-S $smaller-padding-S 0;
	}
	.r-pad-XS {
		padding: $smaller-padding-XS;
	}
	.r-pad-left-XS {
		padding-left: $smaller-padding-XS;
	}
	.r-pad-right-XS {
		padding-right: $smaller-padding-XS;
	}
	.r-pad-top-XS {
		padding-top: $smaller-padding-XS;
	}
	.r-pad-bottom-XS {
		padding-bottom: $smaller-padding-XS;
	}
	.r-pad-0-XS {
		padding-left: $smaller-padding-XS;
		padding-right: $smaller-padding-XS;
	}
	.r-pad-XS-0 {
		padding-top: $smaller-padding-XS;
		padding-bottom: $smaller-padding-XS;
	}
	.r-pad-XS-XS-0 {
		padding: $smaller-padding-XS $smaller-padding-XS 0;
	}
}

@media screen and (min-width: $break-point-1) and (max-width: $break-point-2) {
	.r-radius-S {
		border-radius: $border-radius-S;
	}
	.r-radius-M {
		border-radius: $border-radius-M;
	}
	.r-radius-L {
		border-radius: $border-radius-L;
	}
	.r-horizontal-bar-S {
		$radius-S: $border-radius-S;
		$bar-size-S: calc($radius-S * 2);
		height: $bar-size-S;
		min-height: $bar-size-S;
		max-height: $bar-size-S;
		min-width: $bar-size-S;
		border-radius: $radius-S;
	}
	.r-horizontal-bar-M {
		$radius-M: $border-radius-M;
		$bar-size-M: calc($radius-M * 2);
		height: $bar-size-M;
		min-height: $bar-size-M;
		max-height: $bar-size-M;
		min-width: $bar-size-M;
		border-radius: $radius-M;
	}
	.r-horizontal-bar-L {
		$radius-L: $border-radius-L;
		$bar-size-L: calc($radius-L * 2);
		height: $bar-size-L;
		min-height: $bar-size-L;
		max-height: $bar-size-L;
		min-width: $bar-size-L;
		border-radius: $radius-L;
	}
	.r-vertical-bar-S {
		$radius-S: $border-radius-S;
		$bar-size-S: calc($radius-S * 4);
		width: $bar-size-S;
		min-width: $bar-size-S;
		max-width: $bar-size-S;
		min-height: $bar-size-S;
		border-radius: $radius-S;
	}
	.r-vertical-bar-M {
		$radius-M: $border-radius-M;
		$bar-size-M: calc($radius-M * 4);
		width: $bar-size-M;
		min-width: $bar-size-M;
		max-width: $bar-size-M;
		min-height: $bar-size-M;
		border-radius: $radius-M;
	}
	.r-vertical-bar-L {
		$radius-L: $border-radius-L;
		$bar-size-L: calc($radius-L * 4);
		width: $bar-size-L;
		min-width: $bar-size-L;
		max-width: $bar-size-L;
		min-height: $bar-size-L;
		border-radius: $radius-L;
	}
	.r-font-XXL {
		font-size: $font-size-XXL;
		line-height: $font-size-XXL;
	}
	.r-font-XL {
		font-size: $font-size-XL;
		line-height: $font-size-XL;
	}
	.r-font-L {
		font-size: $font-size-L;
		line-height: $font-size-L;
	}
	.r-font-M {
		font-size: $font-size-M;
		line-height: $font-size-M;
	}
	.r-font-S {
		font-size: $font-size-S;
		line-height: $font-size-S;
	}
	.r-font-XS {
		font-size: $font-size-XS;
		line-height: $font-size-XS;
	}
	.r-gap-XXL {
		gap: $gap-XXL;
	}
	.r-gap-XL {
		gap: $gap-XL;
	}
	.r-gap-L {
		gap: $gap-L;
	}
	.r-gap-M {
		gap: $gap-M;
	}
	.r-gap-S {
		gap: $gap-S;
	}
	.r-gap-XS {
		gap: $gap-XS;
	}
	.r-pad-XXL {
		padding: $padding-XXL;
	}
	.r-pad-left-XXL {
		padding-left: $padding-XXL;
	}
	.r-pad-right-XXL {
		padding-right: $padding-XXL;
	}
	.r-pad-top-XXL {
		padding-top: $padding-XXL;
	}
	.r-pad-bottom-XXL {
		padding-bottom: $padding-XXL;
	}
	.r-pad-0-XXL {
		padding-left: $padding-XXL;
		padding-right: $padding-XXL;
	}
	.r-pad-XXL-0 {
		padding-top: $padding-XXL;
		padding-bottom: $padding-XXL;
	}
	.r-pad-XXL-XXL-0 {
		padding: $padding-XXL $padding-XXL 0;
	}
	.r-pad-XL {
		padding: $padding-XL;
	}
	.r-pad-left-XL {
		padding-left: $padding-XL;
	}
	.r-pad-right-XL {
		padding-right: $padding-XL;
	}
	.r-pad-top-XL {
		padding-top: $padding-XL;
	}
	.r-pad-bottom-XL {
		padding-bottom: $padding-XL;
	}
	.r-pad-0-XL {
		padding-left: $padding-XL;
		padding-right: $padding-XL;
	}
	.r-pad-XL-0 {
		padding-top: $padding-XL;
		padding-bottom: $padding-XL;
	}
	.r-pad-XL-XL-0 {
		padding: $padding-XL $padding-XL 0;
	}
	.r-pad-L {
		padding: $padding-L;
	}
	.r-pad-left-L {
		padding-left: $padding-L;
	}
	.r-pad-right-L {
		padding-right: $padding-L;
	}
	.r-pad-top-L {
		padding-top: $padding-L;
	}
	.r-pad-bottom-L {
		padding-bottom: $padding-L;
	}
	.r-pad-0-L {
		padding-left: $padding-L;
		padding-right: $padding-L;
	}
	.r-pad-L-0 {
		padding-top: $padding-L;
		padding-bottom: $padding-L;
	}
	.r-pad-L-L-0 {
		padding: $padding-L $padding-L 0;
	}
	.r-pad-M {
		padding: $padding-M;
	}
	.r-pad-left-M {
		padding-left: $padding-M;
	}
	.r-pad-right-M {
		padding-right: $padding-M;
	}
	.r-pad-top-M {
		padding-top: $padding-M;
	}
	.r-pad-bottom-M {
		padding-bottom: $padding-M;
	}
	.r-pad-0-M {
		padding-left: $padding-M;
		padding-right: $padding-M;
	}
	.r-pad-M-0 {
		padding-top: $padding-M;
		padding-bottom: $padding-M;
	}
	.r-pad-M-M-0 {
		padding: $padding-M $padding-M 0;
	}
	.r-pad-S {
		padding: $padding-S;
	}
	.r-pad-left-S {
		padding-left: $padding-S;
	}
	.r-pad-right-S {
		padding-right: $padding-S;
	}
	.r-pad-top-S {
		padding-top: $padding-S;
	}
	.r-pad-bottom-S {
		padding-bottom: $padding-S;
	}
	.r-pad-0-S {
		padding-left: $padding-S;
		padding-right: $padding-S;
	}
	.r-pad-S-0 {
		padding-top: $padding-S;
		padding-bottom: $padding-S;
	}
	.r-pad-S-S-0 {
		padding: $padding-S $padding-S 0;
	}
	.r-pad-XS {
		padding: $padding-XS;
	}
	.r-pad-left-XS {
		padding-left: $padding-XS;
	}
	.r-pad-right-XS {
		padding-right: $padding-XS;
	}
	.r-pad-top-XS {
		padding-top: $padding-XS;
	}
	.r-pad-bottom-XS {
		padding-bottom: $padding-XS;
	}
	.r-pad-0-XS {
		padding-left: $padding-XS;
		padding-right: $padding-XS;
	}
	.r-pad-XS-0 {
		padding-top: $padding-XS;
		padding-bottom: $padding-XS;
	}
	.r-pad-XS-XS-0 {
		padding: $padding-XS $padding-XS 0;
	}
}

@media screen and (min-width: $break-point-2) {
	$bigger-default-ratio: 1.5;
	$bigger-border-radius-S: calc($border-radius-S * $bigger-default-ratio);
	$bigger-border-radius-M: calc($border-radius-M * $bigger-default-ratio);
	$bigger-border-radius-L: calc($border-radius-L * $bigger-default-ratio);
	$bigger-font-size-XXL: calc($font-size-XXL * 1.2);
	$bigger-font-size-XL: calc($font-size-XL * 1.2);
	$bigger-font-size-L: calc($font-size-L * 1.2);
	$bigger-font-size-M: calc($font-size-M * 1.2);
	$bigger-font-size-S: calc($font-size-S * 1.2);
	$bigger-font-size-XS: calc($font-size-XS * 1.2);
	$bigger-gap-XXL: calc($gap-XXL * $bigger-default-ratio);
	$bigger-gap-XL: calc($gap-XL * $bigger-default-ratio);
	$bigger-gap-L: calc($gap-L * $bigger-default-ratio);
	$bigger-gap-M: calc($gap-M * $bigger-default-ratio);
	$bigger-gap-S: calc($gap-S * $bigger-default-ratio);
	$bigger-gap-XS: calc($gap-XS * $bigger-default-ratio);
	$bigger-padding-XXL: calc($padding-XXL * $bigger-default-ratio);
	$bigger-padding-XL: calc($padding-XL * $bigger-default-ratio);
	$bigger-padding-L: calc($padding-L * $bigger-default-ratio);
	$bigger-padding-M: calc($padding-M * $bigger-default-ratio);
	$bigger-padding-S: calc($padding-S * $bigger-default-ratio);
	$bigger-padding-XS: calc($padding-XS * $bigger-default-ratio);
	.r-radius-S {
		border-radius: $bigger-border-radius-S;
	}
	.r-radius-M {
		border-radius: $bigger-border-radius-M;
	}
	.r-radius-L {
		border-radius: $bigger-border-radius-L;
	}
	.r-horizontal-bar-S {
		$radius-S: $bigger-border-radius-S;
		$bar-size-S: calc($radius-S * 2);
		height: $bar-size-S;
		min-height: $bar-size-S;
		max-height: $bar-size-S;
		min-width: $bar-size-S;
		border-radius: $radius-S;
	}
	.r-horizontal-bar-M {
		$radius-M: $bigger-border-radius-M;
		$bar-size-M: calc($radius-M * 2);
		height: $bar-size-M;
		min-height: $bar-size-M;
		max-height: $bar-size-M;
		min-width: $bar-size-M;
		border-radius: $radius-M;
	}
	.r-horizontal-bar-L {
		$radius-L: $bigger-border-radius-L;
		$bar-size-L: calc($radius-L * 2);
		height: $bar-size-L;
		min-height: $bar-size-L;
		max-height: $bar-size-L;
		min-width: $bar-size-L;
		border-radius: $radius-L;
	}
	.r-vertical-bar-S {
		$radius-S: $bigger-border-radius-S;
		$bar-size-S: calc($radius-S * 4);
		width: $bar-size-S;
		min-width: $bar-size-S;
		max-width: $bar-size-S;
		min-height: $bar-size-S;
		border-radius: $radius-S;
	}
	.r-vertical-bar-M {
		$radius-M: $bigger-border-radius-M;
		$bar-size-M: calc($radius-M * 4);
		width: $bar-size-M;
		min-width: $bar-size-M;
		max-width: $bar-size-M;
		min-height: $bar-size-M;
		border-radius: $radius-M;
	}
	.r-vertical-bar-L {
		$radius-L: $bigger-border-radius-L;
		$bar-size-L: calc($radius-L * 4);
		width: $bar-size-L;
		min-width: $bar-size-L;
		max-width: $bar-size-L;
		min-height: $bar-size-L;
		border-radius: $radius-L;
	}
	.r-font-XXL {
		font-size: $bigger-font-size-XXL;
		line-height: $bigger-font-size-XXL;
	}
	.r-font-XL {
		font-size: $bigger-font-size-XL;
		line-height: $bigger-font-size-XL;
	}
	.r-font-L {
		font-size: $bigger-font-size-L;
		line-height: $bigger-font-size-L;
	}
	.r-font-M {
		font-size: $bigger-font-size-M;
		line-height: $bigger-font-size-M;
	}
	.r-font-S {
		font-size: $bigger-font-size-S;
		line-height: $bigger-font-size-S;
	}
	.r-font-XS {
		font-size: $bigger-font-size-XS;
		line-height: $bigger-font-size-XS;
	}
	.r-gap-XXL {
		gap: $bigger-gap-XXL;
	}
	.r-gap-XL {
		gap: $bigger-gap-XL;
	}
	.r-gap-L {
		gap: $bigger-gap-L;
	}
	.r-gap-M {
		gap: $bigger-gap-M;
	}
	.r-gap-S {
		gap: $bigger-gap-S;
	}
	.r-gap-XS {
		gap: $bigger-gap-XS;
	}
	.r-pad-XXL {
		padding: $bigger-padding-XXL;
	}
	.r-pad-left-XXL {
		padding-left: $bigger-padding-XXL;
	}
	.r-pad-right-XXL {
		padding-right: $bigger-padding-XXL;
	}
	.r-pad-top-XXL {
		padding-top: $bigger-padding-XXL;
	}
	.r-pad-bottom-XXL {
		padding-bottom: $bigger-padding-XXL;
	}
	.r-pad-0-XXL {
		padding-left: $bigger-padding-XXL;
		padding-right: $bigger-padding-XXL;
	}
	.r-pad-XXL-0 {
		padding-top: $bigger-padding-XXL;
		padding-bottom: $bigger-padding-XXL;
	}
	.r-pad-XXL-XXL-0 {
		padding: $bigger-padding-XXL $bigger-padding-XXL 0;
	}
	.r-pad-XL {
		padding: $bigger-padding-XL;
	}
	.r-pad-left-XL {
		padding-left: $bigger-padding-XL;
	}
	.r-pad-right-XL {
		padding-right: $bigger-padding-XL;
	}
	.r-pad-top-XL {
		padding-top: $bigger-padding-XL;
	}
	.r-pad-bottom-XL {
		padding-bottom: $bigger-padding-XL;
	}
	.r-pad-0-XL {
		padding-left: $bigger-padding-XL;
		padding-right: $bigger-padding-XL;
	}
	.r-pad-XL-0 {
		padding-top: $bigger-padding-XL;
		padding-bottom: $bigger-padding-XL;
	}
	.r-pad-XL-XL-0 {
		padding: $bigger-padding-XL $bigger-padding-XL 0;
	}
	.r-pad-L {
		padding: $bigger-padding-L;
	}
	.r-pad-left-L {
		padding-left: $bigger-padding-L;
	}
	.r-pad-right-L {
		padding-right: $bigger-padding-L;
	}
	.r-pad-top-L {
		padding-top: $bigger-padding-L;
	}
	.r-pad-bottom-L {
		padding-bottom: $bigger-padding-L;
	}
	.r-pad-0-L {
		padding-left: $bigger-padding-L;
		padding-right: $bigger-padding-L;
	}
	.r-pad-L-0 {
		padding-top: $bigger-padding-L;
		padding-bottom: $bigger-padding-L;
	}
	.r-pad-L-L-0 {
		padding: $bigger-padding-L $bigger-padding-L 0;
	}
	.r-pad-M {
		padding: $bigger-padding-M;
	}
	.r-pad-left-M {
		padding-left: $bigger-padding-M;
	}
	.r-pad-right-M {
		padding-right: $bigger-padding-M;
	}
	.r-pad-top-M {
		padding-top: $bigger-padding-M;
	}
	.r-pad-bottom-M {
		padding-bottom: $bigger-padding-M;
	}
	.r-pad-0-M {
		padding-left: $bigger-padding-M;
		padding-right: $bigger-padding-M;
	}
	.r-pad-M-0 {
		padding-top: $bigger-padding-M;
		padding-bottom: $bigger-padding-M;
	}
	.r-pad-M-M-0 {
		padding: $bigger-padding-M $bigger-padding-M 0;
	}
	.r-pad-S {
		padding: $bigger-padding-S;
	}
	.r-pad-left-S {
		padding-left: $bigger-padding-S;
	}
	.r-pad-right-S {
		padding-right: $bigger-padding-S;
	}
	.r-pad-top-S {
		padding-top: $bigger-padding-S;
	}
	.r-pad-bottom-S {
		padding-bottom: $bigger-padding-S;
	}
	.r-pad-0-S {
		padding-left: $bigger-padding-S;
		padding-right: $bigger-padding-S;
	}
	.r-pad-S-0 {
		padding-top: $bigger-padding-S;
		padding-bottom: $bigger-padding-S;
	}
	.r-pad-S-S-0 {
		padding: $bigger-padding-S $bigger-padding-S 0;
	}
	.r-pad-XS {
		padding: $bigger-padding-XS;
	}
	.r-pad-left-XS {
		padding-left: $bigger-padding-XS;
	}
	.r-pad-right-XS {
		padding-right: $bigger-padding-XS;
	}
	.r-pad-top-XS {
		padding-top: $bigger-padding-XS;
	}
	.r-pad-bottom-XS {
		padding-bottom: $bigger-padding-XS;
	}
	.r-pad-0-XS {
		padding-left: $bigger-padding-XS;
		padding-right: $bigger-padding-XS;
	}
	.r-pad-XS-0 {
		padding-top: $bigger-padding-XS;
		padding-bottom: $bigger-padding-XS;
	}
	.r-pad-XS-XS-0 {
		padding: $bigger-padding-XS $bigger-padding-XS 0;
	}
}
