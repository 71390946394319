$font-family: "Proxima Nova", "Helvetica Neue", sans-serif;

$k-primary: #0C2633;
$k-primary-light: #0C263311;
// $k-primary-very-light: #0C263311;
$k-primary-transparent: #0C26331A;
$k-primary-contrast: #FFFFFF;
$k-primary-filter: brightness(0) saturate(100%) invert(11%) sepia(16%) saturate(2830%) hue-rotate(159deg) brightness(89%) contrast(93%);
$k-accent: #3DC0FF;
$k-accent-light: #EFF8FC;
// $k-accent-very-light: #EFF8FC;
$k-accent-transparent: #3DC0FF1A;
$k-accent-contrast: #FFFFFF;
$k-accent-filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(1807%) hue-rotate(170deg) brightness(101%) contrast(102%);
$k-accent-secondary: #A890F3;
$k-accent-secondary-light: #EAE6F6;
$k-accent-secondary-very-light: #F6F4FE;
$k-accent-secondary-transparent: #A890F31A;
$k-accent-secondary-contrast: #FFFFFF;
$k-accent-secondary-filter: invert(64%) sepia(36%) saturate(1790%) hue-rotate(208deg) brightness(96%) contrast(99%);
$k-warn: #DF346E;
$k-warn-light: #DF346E11;
// $k-warn-very-light: #DF346E;
$k-warn-transparent: #DF346E1A;
$k-warn-contrast: #FFFFFF;
$k-warn-filter: invert(39%) sepia(62%) saturate(6352%) hue-rotate(322deg) brightness(93%) contrast(87%);
$k-success: #4AB04A;
$k-success-light: #CFF5D1;
$k-success-very-light: #EEF8EF;
$k-success-transparent: #4AB04A1A;
$k-success-contrast: #FFFFFF;
$k-success-filter: invert(67%) sepia(13%) saturate(1736%) hue-rotate(73deg) brightness(93%) contrast(85%);
$k-warning: #F79939;
$k-warning-light: #FAE7D5;
$k-warning-very-light: #FFF5E6;
$k-warning-transparent: #F799391A;
$k-warning-contrast: #FFFFFF;
$k-warning-filter: invert(72%) sepia(40%) saturate(2054%) hue-rotate(333deg) brightness(101%) contrast(94%);
$k-error: #FF5555;
$k-error-light: #F7CFCF;
$k-error-very-light: #FF55551A; // TODO without transparency
$k-error-transparent: #FF55551A;
$k-error-contrast: #FFFFFF;
$k-error-filter: brightness(0) saturate(100%) invert(68%) sepia(91%) saturate(5142%) hue-rotate(329deg) brightness(115%) contrast(128%);
$k-lightgrey: #FAFAFC;
$k-lightgrey-2: #F7F8FA;
$k-lightgrey-3: #F2F2F7;
$k-lightgrey-filter: invert(96%) sepia(4%) saturate(2198%) hue-rotate(189deg) brightness(99%) contrast(90%);
$k-bordergrey: #DCDCE9;
$k-grey: #B5B5C3;
$k-darkgrey: #7C7C7C;
$k-grey-filter: invert(77%) sepia(6%) saturate(386%) hue-rotate(202deg) brightness(93%) contrast(95%);
$k-pink: #E0346F;
$k-darkgreen: #0f5132;
$k-white-filter: invert(100%) sepia(0%) saturate(7495%) hue-rotate(219deg) brightness(102%) contrast(100%);

// Older color variables (maybe unused)
$k-headergrey: rgba(246, 246, 246, 0.65);
$background-grey: #6E6E82;
$background-light-grey: rgba(236, 236, 236, 0.2);
$k-yellow: #E0A22E;

// Other variables
$break-point-1: 1200px;
$break-point-2: 2400px;
$breakpoint-think-pad: 1280px;
$border-XL: 3px;
$border-L: 2px;
$border-M: 1.5px;
$border-S: 1px;
$border-XS: 0.5px;
$border-radius-XL: 18.5px;
$border-radius-L: 14px;
$border-radius-M: 9px;
$border-radius-S: 6px;
$font-size-XXL: 2.4rem; // 31.2px;
$font-size-XL: 1.8rem; // 23.4px;
$font-size-LL: 1.5rem; // 19.5px;
$font-size-L: 1.25rem; // 16.25px;
$font-size-MM: 1.1rem; // 14.3px;
$font-size-M: 1rem; // 13px;
$font-size-S: 0.9rem; // 11.7px;
$font-size-XS: 0.7rem; // 9.1px;
$line-height-XXL: 2.5rem;
$line-height-XL: 1.9rem;
$line-height-LL: 1.6rem;
$line-height-L: 1.35rem;
$line-height-MM: 1.2rem;
$line-height-M: 1.1rem;
$line-height-S: 1rem;
$line-height-XS: 0.8rem;
$gap-XXL: 30px;
$gap-XL: 25px;
$gap-L: 20px;
$gap-widget: 18px;
$gap-M: 15px;
$gap-S: 10px;
$gap-XS: 5px;
$padding-XXL: 30px;
$padding-XL: 25px;
$padding-L: 20px;
$padding-M: 15px;
$padding-S: 10px;
$padding-XS: 5px;
