
.rotate-in-center {
	-webkit-animation: rotate-in-center 0.8s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
	animation: rotate-in-center 0.8s cubic-bezier(0.600, -0.280, 0.735, 0.045) both;
}

@-webkit-keyframes rotate-in-center {
	0% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
		opacity: 0;
	}
	100% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
		opacity: 1;
	}
}
@keyframes rotate-in-center {
	0% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
		opacity: 0;
	}
	100% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
		opacity: 1;
	}
}

@-webkit-keyframes heartbeat {
	from {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	10% {
		-webkit-transform: scale(0.81);
		transform: scale(0.91);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	17% {
		-webkit-transform: scale(0.88);
		transform: scale(0.98);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	33% {
		-webkit-transform: scale(0.77);
		transform: scale(0.87);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	45% {
		-webkit-transform: scale(1.1);
		transform: scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite 2.5s both;
	animation: heartbeat 1.5s ease-in-out infinite 2.5s both;
}

.heartbeat-nodelay {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	animation: heartbeat 1.5s ease-in-out infinite both;
}

.heartbeat-delay-1 {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite 1s both;
	animation: heartbeat 1.5s ease-in-out infinite 1s both;
}

@keyframes heartbeat {
	from {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: center center;
		transform-origin: center center;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	10% {
		-webkit-transform: scale(0.91);
		transform: scale(0.91);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	17% {
		-webkit-transform: scale(0.98);
		transform: scale(0.98);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
	33% {
		-webkit-transform: scale(0.87);
		transform: scale(0.87);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}
	45% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}
}

// Rotate
.rotate-center {
	-webkit-animation: rotate-center 0.6s infinite both;
	animation: rotate-center 0.6s infinite both;
}

@-webkit-keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}

@keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}
	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}
